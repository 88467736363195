import { axiosClient } from "api";

export const pushNotificationApi = {
  getPushNotification() {
    const url = "/push-notification";
    return axiosClient.get(url);
  },

  postPushNotification(args) {
    const url = "/push-notification";
    let data = { notified_hours: args };
    return axiosClient.post(url, data);
  },
};
