import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Info, ListResponse } from "models";
import { RootState } from "redux/app/store";

export interface InfoSlice {
  value: {
    headquater: ListResponse<Info>;
    branch: ListResponse<Info>;
    office: ListResponse<Info>;
  };
  unread: {
    headquater: number;
    branch: number;
    office: number;
  };
  curUnread: {
    headquater: number;
    branch: number;
    office: number;
  };
}
const initialState: InfoSlice = {
  value: {
    headquater: { list: [], pagination: {} },
    branch: { list: [], pagination: {} },
    office: { list: [], pagination: {} },
  },
  unread: {
    headquater: -1,
    branch: -1,
    office: -1,
  },
  curUnread: {
    headquater: 0,
    branch: 0,
    office: 0,
  },
};

const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    //Headquater
    setHeadquaterInfos(state, action: PayloadAction<ListResponse<Info>>) {
      state.value.headquater = action.payload;
    },
    addHeadquaterInfo(state, action: PayloadAction<ListResponse<Info>>) {
      state.value.headquater = {
        ...state.value.headquater,
        list: [...state.value.headquater.list, ...action.payload.list],
        pagination: action.payload.pagination,
      };
    },
    updateHeadquaterUnRead(state, action: PayloadAction<number>) {
      state.unread.headquater = action.payload > 0 ? action.payload : -1;
    },
    updateHeadquaterCurUnRead(state, action: PayloadAction<number>) {
      state.curUnread.headquater = action.payload;
    },
    //Branch
    setBranchInfos(state, action: PayloadAction<ListResponse<Info>>) {
      state.value.branch = action.payload;
    },
    addBranchInfo(state, action: PayloadAction<ListResponse<Info>>) {
      state.value.branch = {
        ...state.value.branch,
        list: [...state.value.branch.list, ...action.payload.list],
        pagination: action.payload.pagination,
      };
    },
    updateBranchUnRead(state, action: PayloadAction<number>) {
      state.unread.branch = action.payload > 0 ? action.payload : -1;
    },
    updateBranchCurUnRead(state, action: PayloadAction<number>) {
      state.curUnread.branch = action.payload;
    },
    //Office
    setOfficeInfos(state, action: PayloadAction<ListResponse<Info>>) {
      state.value.office = action.payload;
    },
    addOfficeInfo(state, action: PayloadAction<ListResponse<Info>>) {
      state.value.office = {
        ...state.value.office,
        list: [...state.value.office.list, ...action.payload.list],
        pagination: action.payload.pagination,
      };
    },
    updateOfficeUnRead(state, action: PayloadAction<number>) {
      state.unread.office = action.payload > 0 ? action.payload : -1;
    },
    updateOfficeCurUnRead(state, action: PayloadAction<number>) {
      state.curUnread.office = action.payload;
    },
  },
});

//Actions
export const infoActions = infoSlice.actions;

//Selector

export const selectInfoValue = (state: RootState) => state.info.value;
export const selectInfoUnRead = (state: RootState) => state.info.unread;
export const selectInfoCurUnRead = (state: RootState) => state.info.curUnread;

//Reducer
export const infoReducer = infoSlice.reducer;
