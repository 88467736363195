import styled from "styled-components";
import { getTheme } from "utils/utils";

export const ToggleSwitchStyle = styled.div`
  .toggle-switch .toggle-switch-checkbox:checked ~ * .toggle-switch-inner {
    margin-left: 0;
  }
  .toggle-switch .toggle-switch-checkbox:checked ~ * .toggle-switch-switch {
    right: -2px;
  }
  .toggle-switch.small-switch {
    transform: scale(0.8);
  }
  .toggle-switch {
    position: relative;
    width: 65px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;

    &-checkbox {
      display: none;
    }

    &-label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border: 0 solid ${getTheme("colors.gray.800")};
      border-radius: 20px;
      margin: 0;
      &:focus {
        outline: none;
        > span {
          box-shadow: 0 0 2px 5px ${getTheme("colors.primary.yellow")};
        }
      }
      > span:focus {
        outline: none;
      }
    }
    &-inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;
      &:before,
      &:after {
        display: block;
        float: left;
        width: 50%;
        height: 25px;
        padding: 0;
        line-height: 25px;
        font-size: 14px;
        color: white;
        font-weight: bold;
        box-sizing: border-box;
      }
      &:before {
        content: attr(data-yes);
        text-transform: uppercase;
        padding-left: 10px;
        background-color: ${getTheme("colors.primary.green")};
        color: ${getTheme("colors.gray.white")};
      }
    }
    &-disabled {
      background-color: ${getTheme("colors.gray.800")};
      cursor: not-allowed;
      &:before {
        background-color: ${getTheme("colors.gray.800")};
        cursor: not-allowed;
      }
    }
    &-inner:after {
      content: attr(data-no);
      text-transform: uppercase;
      padding-right: 10px;
      background-color: ${getTheme("colors.gray.800")};
      color: ${getTheme("colors.gray.white")};
      text-align: right;
    }
    &-switch {
      display: block;
      width: 30px;
      height: 30px;
      margin: 0;
      box-shadow: ${getTheme("shadows.8")};
      background: ${getTheme("colors.gray.white")};
      position: absolute;
      top: -3px;
      right: 40px;
      border: 0 solid ${getTheme("colors.gray.800")};
      border-radius: 30px;
      transition: all 0.3s ease-in 0s;
    }
  }
`;
