import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SearchSlice {
  value: string;
  searchBox: boolean;
  currentTab: number;
}
const initialState: SearchSlice = {
  value: "",
  searchBox: false,
  currentTab: 1,
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    addSearch(state, action: PayloadAction<string>) {
      state.value = action.payload;
    },
    removeSearch(state) {
      state.value = "";
    },
    setSearchBox(state, action: PayloadAction<boolean>) {
      state.searchBox = action.payload;
    },
    setCurrentTab(state, action: PayloadAction<number>) {
      state.currentTab = action.payload;
    },
  },
});

//Actions
export const searchActions = searchSlice.actions;

//Selector
export const selectSearchValue = (state: { search: { value: string } }) =>
  state.search.value;
export const selectSearchBox = (state: { search: { searchBox: boolean } }) =>
  state.search.searchBox;

export const selectCurrentTab = (state: { search: { currentTab: number } }) =>
  state.search.currentTab;

//Reducer
export const searchReducer = searchSlice.reducer;
