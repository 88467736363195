import { axiosClient } from "api";

export const authApi = {
  crfs() {
    const url = `${process.env.REACT_APP_PUBLIC_APP_URL}/sanctum/csrf-cookie`;
    return axiosClient.get(url);
  },
  login(credentials) {
    const url = "/login";
    return axiosClient.post(url, credentials);
  },

  user() {
    const url = "/user";
    return axiosClient.get(url);
  },
  updateRegistrationID(id) {
    const url = `/registration-id`;
    const data = {
      registration_id: id,
    };
    return axiosClient.post(url, data);
  },
  logout() {
    const url = "/logout";
    return axiosClient.get(url);
  },
};
