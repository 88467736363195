import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Google Analytics 測定 ID を入力して設定
    // https://www.notion.so/c2692b228a7a46cf9f2c73f3be7c4949?v=812cd958b45b4a64b095f32a6ea84200&p=8ca42c9fc3794972a344ffff992a5ef3&pm=s
    ReactGA.initialize("G-11KT4HT2G1");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
  }, [location]);
};

export default usePageTracking;
