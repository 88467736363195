const gray = {
  900: "#222222", // Main Text
  850: "rgb(151,151,151)",
  800: "#C4C4C4",
  700: "#D0D0D0",
  600: "#EDEDED",
  500: "#F5F5F5",
  white: "#FFFFFF",
};

const textColor = {
  primary: gray[900],
  secondary: gray[800],
  hint: gray[700],
  muted: gray[600],
  disabled: gray[500],
  link: "#0095ed",
};

const bodyColor = {
  text: textColor.primary,
  default: gray[100],
  paper: gray["white"],
};

const primaryColor = {
  main: "#F63147",
  organe: "#FA482A",
  blue: "#5579F1",
  yellow: "#E9CF09",
  hightLight: "#FFFF00",
  ligthBlue: "#A0B2EE",
  moreLightBlue: "#F3F6FF",
  green: "#19D00D",
  lightPink: "#F2A699",
  text: "#ffffff",
};

const gradientColor = {
  100: "linear-gradient(90deg, #FA482A 0%, #F52E4A 100%)",
  text: "#ffffff",
};

const warningColor = {
  main: "#F4C521",
  text: textColor.primary,
};
const errorColor = {
  main: "#E70012",
  light: "#FFE1E6",
  text: textColor.primary,
};
const successColor = {
  main: "rgba(51, 208, 103, 1)",
  light: "rgba(51, 208, 103, 0.15)",
  text: textColor.primary,
};
const defaultColor = {
  light: textColor.secondary,
  main: textColor.primary,
  dark: textColor.primary,
  text: textColor.primary,
};

export const colors = {
  default: defaultColor,
  primary: primaryColor,
  gradient: gradientColor,
  text: textColor,
  body: bodyColor,
  warn: warningColor,
  error: errorColor,
  success: successColor,
  gray,
};
