import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Employee } from "models";

export interface LoginPayLoad {
  code: "";
  password: "";
}
export interface AuthSlice {
  logging: Boolean;
  currentEmployee: Employee;
}
const initialState: AuthSlice = {
  logging: false,
  currentEmployee: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, _action: PayloadAction<LoginPayLoad>) {
      state.logging = true;
    },
    loginSucess(state) {
      state.logging = false;
      localStorage.setItem("isLoggedIn", "yes");
    },
    loginFailed(state) {
      state.logging = false;
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("persist:daiwa");
    },
    logout(state) {
      state.logging = false;
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("persist:daiwa");
    },
    setCurrentUser(state, action: PayloadAction<Employee>) {
      state.currentEmployee = action.payload;
    },
    unSetCurrentUser(state) {
      state.currentEmployee = null;
    },
    reset() {
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("persist:daiwa");
    },
  },
});

//Actions
export const authActions = authSlice.actions;

//Selector
export const selectIsLogging = (state: { auth: { logging: Boolean } }) =>
  state.auth.logging;

export const selectCurrentUser = (state: {
  auth: { currentEmployee: Employee };
}) => state.auth.currentEmployee;

//Reducer
export const authReducer = authSlice.reducer;
