import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { connectRouter, routerMiddleware } from "connected-react-router";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import {
  authReducer,
  messageReducer,
  infoReducer,
  searchReducer,
} from "redux/features";
import { history } from "utils";
import rootSaga from "./rootSaga";

const persistConfig = {
  key: process.env.REACT_APP_PUBLIC_APP_NAME,
  blacklist: [],
  storage,
};

const combinedReducer = combineReducers({
  auth: authReducer,
  search: searchReducer,
  info: infoReducer,
  message: messageReducer,
  router: connectRouter(history),
});

const rootReducer = (state, action) => {
  if (action.type === "auth/reset") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware, routerMiddleware(history)),
  devTools: true,
});

setupListeners(store.dispatch);

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
