import { axiosClient } from "api";

export const infoApi = {
  getInfos(args) {
    const { locationP, keywordP, pageP } = args;
    const url = `info/${locationP}?keyword=${keywordP}&page=${pageP}`;
    return axiosClient.get(url);
  },

  countInfo(args) {
    const { locationP } = args;
    const url = `info/${locationP}/count`;
    return axiosClient.get(url);
  },
};
