import { axiosClient } from "api";

export const messageHistoryApi = {
  getList(keyword = "", page = 1) {
    const url = `/messages-histories?keyword=${keyword}&page=${page}`;
    return axiosClient.get(url);
  },
  getDetail(id) {
    const url = `/messages-histories/${id}`;
    return axiosClient.get(url);
  },
  getDetailMessage(id) {
    const url = `/messages-histories/${id}/messages`;
    return axiosClient.get(url);
  },
};
