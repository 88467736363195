export const deviceSize = {
  xs: 425,
  sm: 768,
  md: 1024,
  lg: 1440,
};

export const layoutConstant = {
  containerWidth: "1120px"
};


export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const chatStatusList = [        
    { status: 1, color: "primary.main", icon: "not-support",  text: "未対応" },
    { status: 2, color: "primary.yellow", icon: "supporting",  text: "対応中" },
    { status: 3, color: "primary.blue", icon: "supported",  text: "対応完了" },
  ];
