import { ToggleSwitchStyle } from "./ToggleSwitchStyle";

interface ToggleSwitchProps {
  id: string;
  checked: boolean;
  onChange?: (selected: boolean) => void;
  name: string;
  optionLabels?: any;
  small: boolean;
  disabled?: boolean;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  id,
  name,
  checked,
  onChange,
  optionLabels,
  small,
  disabled,
  ...props
}) => {
  const handleKeyPress = (e) => {
    if (e.keyCode !== 32) return;

    // console.log(e);

    e.preventDefault();
    onChange(!checked);
  };

  return (
    <ToggleSwitchStyle {...props}>
      <div className={"toggle-switch" + (small ? " small-switch" : "")}>
        <input
          type="checkbox"
          name={name}
          className="toggle-switch-checkbox"
          id={id}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        />
        {id ? (
          <>
            <label
              className="toggle-switch-label"
              htmlFor={id}
              tabIndex={disabled ? -1 : 1}
              onKeyDown={(e) => {
                handleKeyPress(e);
              }}
            >
              <span
                className={
                  disabled
                    ? "toggle-switch-inner toggle-switch-disabled"
                    : "toggle-switch-inner"
                }
                data-yes={optionLabels[0]}
                data-no={optionLabels[1]}
                tabIndex={-1}
              />
              <span
                className={
                  disabled
                    ? "toggle-switch-switch toggle-switch-disabled"
                    : "toggle-switch-switch"
                }
                tabIndex={-1}
              />
            </label>
          </>
        ) : null}
      </div>
    </ToggleSwitchStyle>
  );
};

ToggleSwitch.defaultProps = {
  optionLabels: ["ON", "OFF"],
  disabled: false,
};
