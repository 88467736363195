import { Route, Switch, useLocation } from "react-router-dom";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import { useEffect, useMemo } from "react";
import {
  Loading,
  TopPage,
  Error404Page,  
  InfoListPage,
  NotificationSettingPage,  
  DeliveryListPage,
  ChatPage,
  LoginPage,
  ProtectedRoute,
  DeliveryDetailPage,
  UserDetailPage,
  InfoLocationPage,
} from "router";


import { Suspense } from "react";
import {   toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PAGE_ROUTE } from "utils";
import { useAppDispatch } from "redux/app/hooks";
import { push } from "connected-react-router";
import usePageTracking from "hooks/useTracking";

const FancyRoute = (props) => {
  useMemo(() => {
    nprogress.configure({ showSpinner: false });
    nprogress.start();
  }, []);

  useEffect(() => {
    nprogress.done();
  }, []);

  return <Route {...props} />;
};



let appRender = 0;
export default function App() {
  console.log(`appRender = ${appRender++}`);
  const isLoggedIn = Boolean(localStorage.getItem("isLoggedIn"));
  const dispatch = useAppDispatch();      
  const { pathname } = useLocation();

  // LISTENING MESSAGE 
  // -------------- START --------------
  useEffect(() => {

    


    navigator.serviceWorker.addEventListener('message', function(event) {      
      if(event){
        console.log(event.data);
        toast.info(event.data?.notification.title, {
          autoClose: 2000,
        });
      }
    });

  }, []);
  // -------------- END --------------



  
  // CHECK LOGIN STATUS
  // -------------- START --------------
  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(push(PAGE_ROUTE.LOGIN_PAGE));      
    }else{      
      if(pathname === "/"){
        dispatch(push("/owner/unsupported-list"));
      }else{
        dispatch(push(pathname));
      }
    }
  },[isLoggedIn]);
  // -------------- END --------------
  

  // Call the usePageTracking hook
  usePageTracking();

  
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Switch>
          
          {/*認証*/}
          <FancyRoute
            path={PAGE_ROUTE.LOGIN_PAGE}
            exact
            component={() => <LoginPage />}
          />

          {/*プッシュ設定*/}
          <FancyRoute
            path={PAGE_ROUTE.NOTIFICATION_SETTING_PAGE}
            exact
            component={ProtectedRoute(NotificationSettingPage)}
          />

          {/*トーク画面*/}
          <FancyRoute
            path={PAGE_ROUTE.CHAT_PAGE}
            exact
            component={ProtectedRoute(ChatPage)}
          />

          <FancyRoute
            path={PAGE_ROUTE.USER_DETAIL_PAGE}
            exact
            component={ProtectedRoute(UserDetailPage)}
          />

          {/*お知らせ*/}
          <FancyRoute
            path={PAGE_ROUTE.INFO_LIST_PAGE}
            exact
            component={ProtectedRoute(InfoListPage)}
          />
          <FancyRoute
            path={PAGE_ROUTE.INFO_LOCATION_PAGE}
            exact
            component={ProtectedRoute(InfoLocationPage)}
          />

          {/*配信*/}
          <FancyRoute
            path={PAGE_ROUTE.DELIVERY_LIST_PAGE}
            exact
            component={ProtectedRoute(DeliveryListPage)}
          />
          <FancyRoute
            path={PAGE_ROUTE.DELIVERY_DETAIL_PAGE}
            exact
            component={ProtectedRoute(DeliveryDetailPage)}
          />

          {/*要対応者*/}
          {/*友達一蘭*/}
          {/*トーク*/}
          <FancyRoute
            path={PAGE_ROUTE.TOP_PAGE}            
            component={ProtectedRoute(TopPage)}
          />
          

          
          
          {/* error page */}
          <FancyRoute path="**" component={() => <Error404Page />} />
        </Switch>
      </Suspense>
      <ToastContainer autoClose={2000} />
    </>
  );
}
