import { PayloadAction } from "@reduxjs/toolkit";
import { authApi } from "api";
import { push } from "connected-react-router";
import { Employee } from "models";
import { toast } from "react-toastify";
import { call, fork, put, take } from "redux-saga/effects";
import { PAGE_ROUTE } from "utils";
import { authActions, LoginPayLoad } from "./authSlice";

function* handleLogin(payload: LoginPayLoad) {
  try {
    yield call(authApi.crfs);
    const res = yield call(authApi.login, payload);
    if (res.status === 200) {
      yield put(authActions.loginSucess());
      const currentUser: Employee = res.data.data;
      yield put(authActions.setCurrentUser(currentUser));
      yield put(push("/owner/unsupported-list"));

      // const deviceID = yield call(fetchToken);
      // if (deviceID) {
      //   const res = yield call(authApi.updateRegistrationID, deviceID);
      //   if (res.status === 200) {
      //     yield put(authActions.loginSucess());
      //     const currentUser: Employee = res.data.data;
      //     yield put(authActions.setCurrentUser(currentUser));
      //     yield put(push("/owner/unsupported-list"));
      //   }
      // }
    }
  } catch (_e) {
    toast.error(
      "ご登録のIDとパスワードが一致しません。ご確認の上、もう一度ご入力ください。",
      {
        autoClose: 2000,
      }
    );
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("persist:daiwa");
    yield put(authActions.loginFailed());
    yield fork(authSaga);
    // window.location.reload();
  }
}

function* watchLoginFlow() {
  while (true) {
    const isLoggedIn = Boolean(localStorage.getItem("isLoggedIn"));

    if (!isLoggedIn) {
      // get action from dispatch
      const action: PayloadAction<LoginPayLoad> = yield take(
        authActions.login.type
      );
      yield fork(handleLogin, action.payload);
    }

    // Listening dispatch action logout from user
    yield take(authActions.logout.type);
    yield call(handleLogout);
  }
}

function* handleLogout() {
  // Redirect to Login page

  try {
    yield put(push(PAGE_ROUTE.LOGIN_PAGE));
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("persist:daiwa");
    window.location.reload();
    yield put(authActions.reset());
  } catch (error) {
    console.log(error);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("persist:daiwa");
    window.location.reload();
  }
}

export function* authSaga() {
  yield fork(watchLoginFlow);
}
