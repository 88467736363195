import { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { store } from "redux/app/store";
import { Provider } from "react-redux";
import { ConnectedRouter, push } from "connected-react-router";
import { history } from "utils/history";
import { GlobalStyles, PAGE_ROUTE, theme } from "utils";
import { ThemeProvider } from "styled-components";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { axiosClient } from "api";
import { AxiosRequestConfig, AxiosResponse } from "axios";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from "./reportWebVitals";
// import { onSuccess, onUpdate } from "./registrationStatus";
import { authActions } from "redux/features";
//Custom persist test
let persistor = persistStore(store);
let unReadInfoLocal = {headquater:-1,branch:-1,office:-1};
localStorage.setItem("unReadInfo", JSON.stringify(unReadInfoLocal));

const router = (
  <StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </StrictMode>
);

/** Intercept any unauthorized request.
 * dispatch logout action accordingly **/
const { dispatch } = store;
axiosClient.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    const { status, data } = response;
    if (status === 200 && data.status === "success")
      return response;
  },
  function (error) {
    const { status } = error.response;
    switch (status) {
      case 401:
        console.log("401 error");        
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("persist:daiwa");
        dispatch(authActions.reset());  
        dispatch(push(PAGE_ROUTE.LOGIN_PAGE));
        window.location.reload();
        break;      
    }
    return Promise.reject(error);
  }
);


ReactDOM.render(router, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//   onUpdate,
//   onSuccess,
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
