import { axiosClient } from "api";

export const listCountApi = {
  getList(name, kind, keyword = "", page = 1) {
    const url = `/${name}/${kind}?keyword=${keyword}&page=${page}`;
    return axiosClient.get(url);
  },
  getCount(name, kind) {
    const url = `/${name}/${kind}/count`;
    return axiosClient.get(url);
  },
};
