import { themeGet } from "@styled-system/theme-get";
import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale("ja");

export const getTheme = (query: string, fallback?: string) =>
  themeGet(query, fallback);

export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match("rgba")) {
    let triplet = hex.slice(5).split(",").slice(0, -1).join(",");
    return triplet;
  }

  let c;

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
  }
};

export const getSearchQueryObj = (key) => {
  const searchQuery = location.search;
  const queryObj = {};
  searchQuery
    .slice(1)
    .split("&")
    .map((str) => (queryObj[str.split("=")[0]] = str.split("=")[1]));
  return queryObj[key];
};

export const formatJapanDate = (date) => {
  return dayjs(date).format("YYYY年MM月DD日 (dd)");
};
export const formatNormalDate = (date) => {
  return dayjs(date).format("yyyy/MM/dd");
};

export const formatHourMinutes = (date) => {
  return dayjs(date).format("HH:mm");
};

export const formatMessageTime = (date) => {
  return dayjs(date).format("YYYY/MM/DD HH:mm:ss");
};

/**
 * 当日: 07:34
 * 当日以外: 12/24
 * 1年以上前: 2021/12/24
 * @param date string
 * @returns string
 */
export const displayMessageTime = (date): string => {
  const today = dayjs();
  const date1 = dayjs(date);

  if (date1.isSame(today, "day")) {
    return date1.format("HH:mm");
  } else if (date1.isSame(today.subtract(1, "year"), "year")) {
    return date1.format("YYYY/MM/DD");
  } else {
    return date1.format("MM/DD");
  }
};

export const weekDays = [
  "月曜日",
  "火曜日",
  "水曜日",
  "木曜日",
  "金曜日",
  "土曜日",
  "日曜日",
];
