import { FlexBox, Spinner } from "components/atoms";
import { lazy } from "react";

/*認証*/
export const LoginPage = lazy(() => import("pages/auth/LoginPage"));
/*要対応者*/
/*友だち*/
/*トーク*/
export const TopPage = lazy(() => import("pages/generals/TopPage"));

/*お知らせ*/
export const UserDetailPage = lazy(
  () => import("pages/generals/UserDetailPage")
);
/*お知らせ*/
export const InfoListPage = lazy(() => import("pages/generals/InfoListPage"));
export const InfoLocationPage = lazy(
  () => import("pages/generals/InfoLocationPage")
);

/*配信履歴*/
export const DeliveryListPage = lazy(
  () => import("pages/generals/DeliveryListPage")
);
export const DeliveryDetailPage = lazy(
  () => import("pages/generals/DeliveryDetailPage")
);

/*トーク画面/*/
export const ChatPage = lazy(() => import("pages/generals/ChatPage"));

/*プッシュ設定*/
export const NotificationSettingPage = lazy(
  () => import("pages/generals/NotificationSettingPage")
);

/* error pages */
export const Error404Page = lazy(() => import("pages/generals/Error404Page"));

export const ExamplePage = lazy(() => import("pages/generals/ExamplePage"));

/* Loading */
export const Loading = () => {
  return (
    <FlexBox alignItems="center" justifyContent="center" height="100vh">
      <Spinner
        size={60}
        border="6px solid"
        borderColor="primary.main"
        borderTop="6px solid white"
      ></Spinner>
    </FlexBox>
  );
};
