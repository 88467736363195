import { useAppDispatch } from "redux/app/hooks";
import { push } from "connected-react-router";
import { PAGE_ROUTE } from "utils";

export const ProtectedRoute = (ProtectedComponent) => {
  return (props) => {
    if (typeof window !== "undefined") {
      const isLoggedIn = Boolean(localStorage.getItem("isLoggedIn"));
      const dispatch = useAppDispatch();      
      if (!isLoggedIn) {
        dispatch(push(PAGE_ROUTE.LOGIN_PAGE));
        window.location.reload();
      } else {
        return <ProtectedComponent {...props} />;
      }
    }
    return null;
  };
};
