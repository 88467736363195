import { axiosClient, axiosClientMultiForm } from "api";

export const messageApi = {
  getMessages(talkRoomId) {
    const url = `/talk-rooms/${talkRoomId}`;
    return axiosClient.get(url);
  },
  postMessage(
    talkRoomId,
    messageText = "",
    messageImage = "",
    messageType = 1,
    options
  ) {
    const url = `/talk-rooms/${talkRoomId}`;
    const bodyFormData = new FormData();
    bodyFormData.append("message_text", messageText);
    bodyFormData.append("message_image", messageImage);
    bodyFormData.append("message_type", messageType.toString());
    return axiosClientMultiForm.post(url, bodyFormData, options);
  },
  postResponse(talkRoomId, responseStatus) {
    const url = `/talk-rooms/${talkRoomId}/response_status`;
    const payload = {
      message_status: responseStatus,
    };
    return axiosClient.post(url, payload);
  },
};
