export enum PAGE_ROUTE {
  LOGIN_PAGE = "/auth/login",
  SEARCH_PAGE = "/search",
  //要対応者
  HOME = "/",

  TOP_PAGE = "/:name/:kind",

  UNSUPPORT_LIST_PAGE = "/:name/unsupported-list",

  TALK_ROOM_PAGE = "/:name/talk-rooms",

  //友だち
  FRIEND_PAGE = "/:name/friend",

  //オーナー個別
  USER_DETAIL_PAGE = "/user/:kind/:id",

  //トーク画面
  CHAT_PAGE = "/chat/:talkRoomID",

  //お知らせ
  INFO_LIST_PAGE = "/info",

  INFO_LOCATION_PAGE = "/:location/info",

  SALE_OFFICE_INFO_LIST_PAGE = "/office/info",
  HEAD_INFO_LIST_PAGE = "/headquater/info",
  BRANCH_INFO_LIST_PAGE = "/branch/info",

  DELIVERY_LIST_PAGE = "/delivery/list",
  DELIVERY_DETAIL_PAGE = "/delivery/:id",

  //プッシュ設定
  NOTIFICATION_SETTING_PAGE = "/notification/setting",

  //静的ページ
  MANUAL_PAGE = "/page/manual",
  COMPANY_PAGE = "/page/company",
  POLICY_PAGE = "/page/policy",
  TERMS_PAGE = "/page/terms",

  //エラー
  ERROR = "/not-found",
}
